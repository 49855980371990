import React from "react";
import Layout from "../components/Layout";
import HeaderSection from "../components/HeaderSection";
import CourseCards from "../components/CourseCardsLanding";
import Logo from "/public/assets/A-logo-primary.svg";
import { useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";


const NotFoundPage = ({ location }) => {
  const theme = useTheme();
  return (
    <Layout location={location}>
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: theme.spacing(4),
        }}
      >
        <Logo fill={theme.palette.primary.main} height={100} />
      </Container>
      <HeaderSection
        header="404 page not found"
        subheader="If you wonder what this means, have a look at our web development class and learn more about HTTP standard response codes!"
        location={location}
      />
      <CourseCards location={location} />
    </Layout>
  );
};

export default NotFoundPage;
