import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CabButton from "../CabButton";
import styles from "./styles";
import LogoC from "../../../public/assets/C-logo-primary.svg";
import LogoA from "../../../public/assets/A-logo-primary.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grow from "@material-ui/core/Grow";

import Box from "@material-ui/core/Box";
const courses = [
  {
    title: "Web Development",
    buttonText: "Web Development",
    subheader: "Building Tomorrow’s Web",
    length: 3,
    description: ["HTML", "CSS", "JavaScript", "React"],
    text: "Our Full-Stack Web Development course covers both Front and Back-End, giving you a comprehensive understanding of Web Development. You'll learn how to build dynamic, responsive web applications using modern web technologies such as React, Node.js, Next.js, and MongoDB. By the end of the course you'll have built several portfolio projects, and you’ll have the skills to enter the market as a junior web developer.",
    to: "web-development",
    nextStart: [],
  },
  {
    title: "Data Analytics",
    buttonText: "Data Analytics",

    subheader: "Unleash the Power of Data",
    length: 5,
    description: ["graphs", "forest graphs", "more graphs"],
    text: "Become data analyst",
    to: "data-science",
  },
];

const CoursesCards = ({ location, classes }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const mobile300PxDown = useMediaQuery("(max-width:300px)");

  const [checked, setChecked] = useState(true);

  return (
    <Grid container className={classes.container}>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 2000 } : {})}
      >
        <Grid item xs={12} md={6} className={classes.grid}>
          <Box letterSpacing={smDown ? 0 : 1}>
            <Typography component="h2" variant="h2" color="inherit">
              <LogoC height={50} fill={theme.palette.secondary.main} />
              {courses[0].subheader}
            </Typography>
          </Box>

          <CabButton to={`/courses/${courses[0].to}`}>
            {courses[0].buttonText}
          </CabButton>
        </Grid>

      </Grow>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 2500 } : {})}
      >
        <Grid item xs={12} md={6} className={classes.grid}>
          {smDown ? (
            <React.Fragment>
              <Box letterSpacing={mobile300PxDown ? 1 : 0}>
                <Typography component="h2" variant="h2" color="inherit">
                  <LogoA height={50} fill={theme.palette.secondary.main} />
                  {courses[1].subheader}
                </Typography>
              </Box>

              <CabButton to={`/courses/${courses[1].to}`}>
                {courses[1].buttonText}
              </CabButton>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CabButton to={`/courses/${courses[1].to}`}>
                {courses[1].buttonText}
              </CabButton>
              <Box letterSpacing={1}>
                <Typography component="h2" variant="h2" color="inherit">
                  <LogoA height={50} fill={theme.palette.secondary.main} />
                  {courses[1].subheader}
                </Typography>

              </Box>
            </React.Fragment>
          )}
        </Grid>
      </Grow>
    </Grid>
  );
};
CoursesCards.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};
export default withStyles(styles)(CoursesCards);
