const styles = (theme) => ({
  grid: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  container: {
    justifyContent: "space-between",
  },
});
export default styles;
